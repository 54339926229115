.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
  background: url('../images/slider2.png') no-repeat center center/cover;
  text-align: center;
  color: white;
}

.hero-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  line-height: normal;
  font-weight: bolder;
}

.hero-section button {
  padding: 10px 20px;
  font-size: 1.5rem;
  color: rgb(31, 30, 30);
  background-color: rgb(245, 242, 242);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  
  font-weight: bolder;
}

.hero-section button:hover {
  background-color: #333;
}
