.digital-marketing-process {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 40px 20px;
}

.digital-marketing-process h1 {
  font-size: 36px;
  color: #e74c3c;
  margin-bottom: 20px;
}

.steps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.process-step:hover {
  background-color: blue;
  color: white;
}

.process-step {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  width: 200px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.process-step-icon {
  margin-bottom: 10px;
}

.process-step-icon img {
  width: 50px; /* Adjust size as needed */
  height: 50px;
}

.process-step-number {
  font-size: 24px;
  font-weight: bold;
  color: #e74c3c;
}

.process-step h2 {
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}

.process-step ul {
  list-style: none;
  padding: 0;
}

.process-step li {
  margin: 5px 0;
  font-size: 14px;
  color: #555555;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .steps {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .process-step {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .process-step {
    width: 100%;
  }
}
