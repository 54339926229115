*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
}
.colorball-green {
 margin-left:1000px;
 margin-top:-300px;
 width:50px;
 height:50px;
}
.services {
  padding: 0px 20px;
  background: hsl(0, 0%, 100%);
  text-align: left;
  margin-bottom: 50px;
}

.services h2 {
  margin-bottom: -4px;
  font-size: 42px;
  color: #000;
  font-weight: bold;
}

.services-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  justify-items: center;
  margin: 0 auto; /* Center the container */
  max-width: 1200px; /* Limit the max width for large screens */
  padding: 0 20px; /* Add padding for small screens */
  margin-top: -87px;
}

.service {
  background-color: rgb(255, 255, 255); /* Set background color to white */
  color: #000; /* Set initial text color */
  padding: 20px;
  border-radius: 10px;
  max-width: 300px;
  position: relative;
  transition: transform 0.3s linear, background-color 0.3s ease, color 0.3s ease;
  animation: moveUpDown 9s infinite alternate;
  border: 1px solid #ccc; /* Add border */
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.1); /* Add subtle box shadow */
  margin: 10px; /* Simplify margin */
}

.service:nth-child(1) {
  animation-delay: 0s;
}

.service:nth-child(2) {
  animation-delay: 0.5s;
}

.service:nth-child(3) {
  animation-delay: 1s;
}

.service:nth-child(4) {
  animation-delay: 1.5s;
}

.service h3 {
  margin-bottom: 10px;
}

.service:active,
.service:hover {
  background-color: hsl(183, 82%, 34%);
  transform: translateY(-10px);
  color: white;
  border: 0px solid #10999f;
  box-shadow: 0 0px 20px #10999f; /* Add box shadow */
}

.service:active::after {
  background-color: transparent; /* Remove pseudo-element background color on click */
}

.service:active {
  background-color: #10999f !important; /* Change background color on click */
}

@media (max-width: 768px) {
  .services {
    margin-top: 20px;
  }
}
@media (max-width: 480px) {
.colorball-green {
  margin-left:0px;
}
}

@media (min-width: 768px) {
  .services-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .service:nth-child(odd) {
    transform: translateY(-40px);
  }

  .service:nth-child(even) {
    transform: translateY(40px);
  }
}

@media (min-width: 1024px) {
  .services-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .service:nth-child(odd) {
    transform: translateY(-40px);
  }

  .service:nth-child(even) {
    transform: translateY(40px);
  }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(-30px);
  }
  20% {
    transform: translateY(50px);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(50px);
  }
  80% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(50px);
  }
}
