/* Slogan.css */

.slogan-section {
  text-align: center;
  margin: 81px;
  max-width: 1200px;
  height: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slogan-content {
  margin-bottom: 20px;
}

.slogan h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: #171616;
}

.slogan p {
  font-size: 18px;
  margin-bottom: 30px;
  color: #0c0c0c;
}

.slogan-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.slogan-image {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .slogan-section {
    padding: 40px 20px;
    margin: 20px;
  }

  .slogan h1 {
    font-size: 42px;
  }

  .slogan p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .slogan-section {
    padding: 30px 20px;
    margin: 20px;
  }

  .slogan h1 {
    font-size: 36px;
  }

  .slogan p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .slogan-section {
    padding: 20px 10px;
    margin: 10px;
  }

  .slogan h1 {
    font-size: 28px;
  }

  .slogan p {
    font-size: 14px;
  }
}

.map-container {
  position: relative;
}

.map-image {
  width: 100%;
  max-width: 800px; /* Adjust based on your preference */
  height: auto;
}

.animated-ball {
  position: absolute;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  /* You can change the color as needed */
}

.ball1 {
  top: 10%; /* Adjust starting point */
  left: 50%; /* Adjust starting point */
  background-color: red;
}

.ball2 {
  top: 10%; /* Adjust starting point */
  left: 50%; /* Adjust starting point */
  background-color: blue;
}

.ball3 {
  top: 40%; /* Adjust starting point */
  left: 50%; /* Adjust starting point */
  background-color: blueviolet;
}

.animate.ball1 {
  animation: moveBall1 2s infinite,ballcolor1 4s;

}

.animate.ball2 {
  animation: moveBall2 2s infinite,ballcolor2 4s;
}

.animate.ball3 {
  animation: moveBall3 2s infinite,ballcolor3 2s;
}
@keyframes ballcolor1{
  0% {background-color: red;}
  25% {background-color: red;}
  50% {background-color: red;}
  75% {background-color: red;}
  100%{background-color: blueviolet;}
}

@keyframes ballcolor2{
  0% {background-color: blue;}
  25% {background-color: blue;}
  50% {background-color: blue;}
  75% {background-color: blue;}
  100%{background-color: blueviolet;}
}

@keyframes moveBall1 {
  0% {
    top: 47%; /* Ending point */
    left: 70%; /* Ending point */
    
  }
  100% {
    top: 10%; /* Starting point */
    left: 50%; /* Starting point */
   
  }
}

@keyframes moveBall2 {
  0% {
    top: 29%; /* Ending point */
    left: 33%; /* Ending point */
  }
  100% {
    top: 10%; /* Starting point */
    left: 48%; /* Starting point */
  }
}

@keyframes moveBall3 {
  0% {
    top: 37%; /* Ending point */
    left: 51%; /* Ending point */
  }
  100% {
    top: 10%; /* Starting point */
    left: 50%; /* Starting point */
  }
}
