\.content {
  text-align: center;
  padding: 50px 20px;
  background: #f4f4f4;
}

.content h1 {
  font-size: 48px;
  margin-bottom: 20px;
}

.content p {
  font-size: 18px;
  margin-bottom: 30px;
}

.content-section,
.marketing-innovation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

.images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-row {
  display: flex;
  flex-direction: row;
}

.image {
  width: 200px;
  height: 200px;
  margin: 10px;
  background-color: #ccc; /* Replace with actual background images or styles */
}

.text-content {
  max-width: 500px;
  padding: 20px;
}

button {
  background-color: #ff5a5f;
  color: #fff;
  border: none;
  padding: 15px 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

button:hover {
  background-color: #e04e4e;
}

@media (max-width: 768px) {
  .content h1 {
    font-size: 36px;
  }

  .content p {
    font-size: 16px;
  }

  .content-section,
  .marketing-innovation {
    flex-direction: column;
  }

  .image {
    width: 150px;
    height: 150px;
  }

  .text-content {
    text-align: center;
    padding: 10px;
  }

  button {
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .content h1 {
    font-size: 28px;
  }

  .content p {
    font-size: 14px;
  }

  .image {
    width: 100px;
    height: 100px;
  }

  .text-content {
    text-align: center;
    padding: 5px;
  }

  button {
    padding: 8px 16px;
  }
}
