.marketing-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

.section-header {
  text-align: center;
  margin-bottom: 40px;
}
/* MarketingSection.css */

.rotating-image, .rotating-image1 {
  position: absolute;
  animation: rotate 5s linear infinite;
}

/* Default sizes */
/* .rotating-image {
  width: 175px !important;
  height: 181px !important;
}

.rotating-image1 {
  width: 170px !important;
  height: 170px !important;
} */

@media (max-width: 768px) {
  .rotating-image, .rotating-image1 {
    width: 150px !important;
    height: 150px !important;
  }
}

@media (max-width: 480px) {
  .rotating-image,.rotating-image1 {
    width: 20px !important;
    height: 20px !important;
    position: absolute;
  }
  .text-wrapper1 {
    margin-top: 15px;
  }

  
}

.section-header h1 {
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
  font-size: 2.1em;
}

.section-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: -210px !important;
}

.content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  margin-bottom: 155px;
}

.content-item.reverse {
  flex-direction: column-reverse;
}

.image-wrapper {
  width: 100%;
  max-width: 440px;
  margin-bottom: 20px;
  position: relative;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: -20px;
}

.text-wrapper {
  max-width: 600px;
  margin-bottom: 60px;
}

.text-wrapper1 {
  max-width: 600px;
 
 
   }

   @media (max-width: 480px) {
    .text-wrapper1 {
     margin-top: 260px;
     
     
       }
       .section-header h1 {
        font-weight: bold;
        margin-bottom: 10px;
        color: black;
        font-size:1.5em;
    }
      }
.text-wrapper h2,.text-wrapper1 h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: black;
}

.text-wrapper p{
  font-size: 1em;
  color: #555;
  margin-bottom: 20px;
  text-align: center;
}
.text-wrapper1 p
{
  font-size: 1em;
  color: #555;
  margin-bottom: 20px;

}
.text-wrapper button,.text-wrapper1 button {
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 21px;
}

.text-wrapper button:hover,.text-wrapper1 button:hover {
  background-color: #ff4b4b;
}

.rotating-image {
  position: absolute;
  width: 175px !important; /* Reduced size */
  height: 181px !important; /* Reduced size */
  animation: rotate 5s linear infinite;
}


.rotating-image1 {
  position: absolute;
  width: 170px !important; /* Reduced size */
  height: 170px !important; /* Reduced size */
  animation: rotate 5s linear infinite;
}

.small-logo {
  width: 45px;
  height: 45px;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .section-content {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .content-item {
    flex-direction: row;
    text-align: left;
  }

  .content-item.reverse {
    flex-direction: row-reverse;
  }

  .image-wrapper {
    flex: 1;
  }

  .text-wrapper {
    flex: 2;
    margin-left: 55px;
  
  }

  .text-wrapper.reverse {
    margin-left: 30px;
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .marketing-section {
    padding: 20px;
  }

  .section-header {
    margin: 20px;
  }

  .content-item {
    margin: 20px 0;
  }

  .text-wrapper button {
    margin-bottom: 20px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}