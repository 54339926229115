.color-bg {
  background-color: #2D1645;
  padding-bottom: 3cm;
  padding-top: 2cm;
}

a {
  text-decoration: none;
  text-transform: capitalize;
}

i {
  font-size: 18px;
  margin-right: 5px;
  padding: 0 4px;
  border-radius: 5px;
}

.bi-facebook, .bi-linkedin {
  background-color: rgba(5, 54, 218, 0.897);
}

.bi-instagram {
  background: linear-gradient(to right top, rgb(201, 113, 91), rgba(200, 23, 40));
}

.bi-youtube {
  background-color: rgb(204, 20, 10);
}

/* Responsive styles */
@media (max-width: 992px) {
  .text-center {
    text-align: center;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .color-bg {
    padding-bottom: 2cm;
    padding-top: 1.5cm;
  }

  i {
    font-size: 16px;
    padding: 0 3px;
  }

  .pb-2 {
    padding-bottom: 1rem;
    text-align: center;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
    text-align: center;
  }
}

@media (max-width: 576px) {
  .color-bg {
    padding-bottom: 1.5cm;
    padding-top: 1cm;
  }

  i {
    font-size: 14px;
    padding: 0 2px;
  }

  .pb-2 {
    padding-bottom: 0.5rem;
    text-align: center;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .color-bg {
    padding-bottom: 1.5cm;
    padding-top: 1cm;
  }

  i {
    font-size: 14px;
    padding: 0 2px;
  }

  .pb-2 {
    padding-bottom: 0.5rem;
    text-align: center;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
    text-align: center;
  }
}
