.main-container {
  padding: 20px;
  transform: rotate(355deg);
  width: 1500px;
  margin-top: 135px;
}

.jayesh-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: max-content;
  animation: slideRightToLeft 15s linear infinite;
  gap: 178px;
}

.main {
  border: 2px solid #000;
  height: 700px;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-image: url("../images/slider.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: -122px !important;
}

.container {
  width: 250px;
  height: 350px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 2s;
  position: relative;
}

.container.enlarged {
  width: 200px;
  height: 200px;
  z-index: 1; /* Ensure enlarged image is on top */
  margin-top: -20px;
  transform: rotate(180deg);
}

.container-image {
  max-width: 183%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 25px;
  transition: transform 0.5s ease; /* Smooth transition for rotation */
  transform: rotate(360deg);
}

@keyframes slideRightToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.details-card {
 
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overlay-image {
  max-width: 500px;
  max-height: 450px;
  border-radius: 10px;
  transform: rotate(340deg);
}

@media (max-width: 768px) {
  .main {
    height: 300px;
    width: 100%;
  }
  .main-container {
    margin-top: -45px;
  }
  .container {
    width: 200px;
    height: 200px;
  }
  .container.enlarged {
    width: 230px;
    height: 230px;
    margin-top: 0px;
  }
}