*,
*::before,
*::after {
    box-sizing: border-box;
}


body {
    overflow-x: hidden;
}

.slogan h1 {
    text-transform: capitalize;
    font-weight: 900;
}

.slogan p {
    text-align: justify;
}

.btn2 {
    border: 1px solid;
    border-radius: 25px;
    background-color: rgb(235, 107, 68);
    color: white;
    position: relative;
    z-index: 1;
    margin-top: 16px;
}

.button-container {
    position: relative;
    display: inline-block;
}

.arrow-animation {
    position: absolute;
    top: 50%;
    left: 130%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 1000;
}

.cylinder {
    width: 284px;
    height: 448px;
    padding: 10px;
    border-bottom-left-radius: 400px;
    border-bottom-right-radius: 400px;
    background-color: rgb(227, 54, 169);
    position: absolute;
    margin-bottom: 50px; /* Added margin-bottom */
    right:306px;
}

.cylinder1 {
    width: 267px;
    height: 570px;
    padding: 20px;
    background-color: rgb(82, 37, 174);
    border-bottom-left-radius: 400px;
    border-bottom-right-radius: 400px;
    position: absolute;
    right: 124px;
    margin-bottom: 50px; /* Added margin-bottom */
}

.poly {
    width: 127px;
    height: 293px;
    padding: 23px 20px;
    background-color: rgb(91, 182, 102);
    border-bottom-left-radius: 400px;
    border-bottom-right-radius: 400px;
    position: absolute;
    top: 0;
    left: 0;
}

.poly1 {
    background-color: rgb(239, 177, 230);
    left: 129px;
}

.poly2 {
    background-color: rgb(218, 42, 118);
    height: 143px;
    left: 132px;
}

.poly3, .poly4 {
    background-color: rgb(225, 114, 114);
    top: 144px;
    left: 2px;
    border-top-left-radius: 400px;
    border-top-right-radius: 400px;
}

.poly4 {
    background-color: rgb(158, 100, 212);
    left: 134px;
}

.img1, .img5, .img7 {
    position: absolute;
    left: 3px;
    animation: moveUp 4s alternate infinite linear;
}

.img2 {
    background-color: rgb(158, 100, 212);
    position: absolute;
    bottom: 0;
    left: -1px;
    top: 132px;
    padding: 55px 50px;
}

.img3 {
    position: absolute;
    left: 65px;
    top: 286px;
    animation: rotate 10s linear infinite;
}

.img4 {
    position: absolute;
    top: 8px;
    left: 2px;
    width: 127px;
    animation: rotate 10s linear infinite;
}

.img6 {
    position: absolute;
    top: 420px;
    left: 56px;
    animation: rotate 10s linear infinite;
}

.img8 {
    margin-left: 260px;
    margin-top: auto;
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

@keyframes moveUp {
    from {
        top: 0px;
    }
    to {
        top: 100px;
    }
}

@keyframes moveDown {
    from {
        top: 100px;
    }
    to {
        top: 0px;
    }
}

.img1, .img7 {
    animation: moveUp 4s alternate infinite linear;
}

.img5 {
    animation: moveDown 4s alternate infinite linear;
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .cylinder, .cylinder1 {
        width: 200px;
        height: 350px;
    }

    .poly {
        width: 90px;
        height: 200px;
    }

    .poly1, .poly2, .poly3, .poly4 {
        width: 90px;
        height: 200px;
    }

    .img2 {
        padding: 40px;
    }

    .img3, .img4, .img6 {
        width: 100px;
    }
}

@media (max-width: 992px) {
    .cylinder, .cylinder1 {
        width: 150px;
        height: 250px;
    }

    .poly {
        width: 70px;
        height: 150px;
    }

    .poly1, .poly2, .poly3, .poly4 {
        width: 70px;
        height: 150px;
    }

    .img2 {
        padding: 30px;
    }

    .img3, .img4, .img6 {
        width: 80px;
    }
}

@media (max-width: 768px) {
    .cylinder, .cylinder1 {
        width: 120px;
        height: 200px;
    }

    .poly {
        width: 60px;
        height: 120px;
    }

    .poly1, .poly2, .poly3, .poly4 {
        width: 60px;
        height: 120px;
    }

    .img2 {
        padding: 20px;
    }

    .img3, .img4, .img6 {
        width: 60px;
    }

    .btn2 {
        width: 100%;
    }

    h1 {
        font-size: 40px;
    }

    p {
        font-size: 14px;
    }

    .img8 {
        margin-left: 150px;
    }
}

@media (max-width: 576px) {
    .cylinder, .cylinder1 {
        width: 100px;
        height: 150px;
    }

    .poly {
        width: 50px;
        height: 100px;
    }

    .poly1, .poly2, .poly3, .poly4 {
        width: 50px;
        height: 100px;
    }

    .img2 {
        padding: 15px;
    }

    .img3, .img4, .img6 {
        width: 50px;
    }

    .btn2 {
        width: 100%;
    }

    h1 {
        font-size: 30px;
    }

    p {
        font-size: 12px;
    }

    .img8 {
        margin-left: 100px;
    }
}
@media (max-width: 480px) {
    body {
        overflow-x: hidden;
    }
    
    .cylinder, .cylinder1 {
        width: 90px;
        height: 135px;
    }

    .poly {
        width: 45px;
        height: 90px;
    }

    .poly1, .poly2, .poly3, .poly4 {
        width: 45px;
        height: 90px;
    }

    .img2 {
        padding: 10px;
    }

    .img3, .img4, .img6 {
        width: 45px;
    }

    .btn2 {
        width: 100%;
    }

    h1 {
        font-size: 25px;
    }

    p {
        font-size: 10px;
    }

    .img8 {
        margin-left: 50px;
    }

    .col:nth-child(2) {
        display: none;
    }
    .col.p-5.ms-4 {
        padding: 0.5rem 0.5rem !important;
        margin-left: 0 !important;
    }
    .col.p-5.ms-4 p{
        padding: 0px !important;
        margin: 40px !important;
        text-align: center !important;
        /* margin-left: -56px !important; */
    }
    .responsive-paragraph::before {
        content: 'Get your brand recognized by people with our\nmarketing channel. Branding, Marketing, SEO,\nSMM & SEM all at one place.';
        white-space: pre;
    }
    .col {
    padding: 5rem; /* Adjust as needed */
    margin-left: 2.5rem; /* Adjust as needed */
  }
  @media (max-width: 768px) {
    .col {
      padding: 2rem; /* Adjust padding for smaller screens */
      margin-left: 1rem; /* Adjust margin for smaller screens */
    }
  }
  
  /* Media query for screens smaller than 576px */
  @media (max-width: 576px) {
    .col {
      padding: 1rem; /* Further adjust padding for very small screens */
      margin-left: 0.5rem; /* Further adjust margin for very small screens */
    }
  }
  @media (max-width: 480px) {
    .col {
      padding: 1rem; /* Further adjust padding for very small screens */
      margin-left: 0rem; /* Further adjust margin for very small screens */
    }
  }
  
}
.box {
    padding: 5rem; /* Adjust as needed */
    margin-left: 2.5rem; /* Adjust as needed */
  }
  @media (max-width: 768px) {
    .box {
      padding: 2rem; /* Adjust padding for smaller screens */
      margin-left: 1rem; /* Adjust margin for smaller screens */
    }
  }
  
  /* Media query for screens smaller than 576px */
  @media (max-width: 576px) {
    .box {
      padding: 1rem; /* Further adjust padding for very small screens */
      margin-left: 0.5rem; /* Further adjust margin for very small screens */
    }
  }
  @media (max-width: 480px) {
    .box {
      padding: 1rem; /* Further adjust padding for very small screens */
      margin-left: 0rem; /* Further adjust margin for very small screens */
    }
  }