/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}



/* Responsive styles */
@media (max-width: 768px) {
.modal-content {
  max-width: 90%;
  padding: 15px;
}
}

@media (max-width: 480px) {
.modal-content {
  max-width: 50%;
  padding: 10px;
}

.close-button {
  top: 5px;
  right: 5px;
  font-size: 1.2em;
}
}